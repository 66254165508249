/**
 * Define the name of keys for simple storage
 */
export enum Contract {
    token = 'token',
    addr = 'addr',
    destinyAddress = 'destinyAddress',
    selectaddr = 'selectaddr',
    selectaddrdes = 'selectaddrdes',
    ManualDestino = 'ManualDestino',
    fact = 'fact',
    shr = 'shr',
    step1 = 'shipping',
    step2 = 'details',
    step3 = 'guia',
    step4 = 'facturacion',
    ManualOrigen = 'ManualOrigen',
    cotizacion = 'cotizacion',
    pickup = 'pickup',
    pagoahora = 'pagoahora',
    pagoreco = 'pagoreco',
    pagocollect = 'pagocollect',
    Totalpagoahora = 'Totalpagoahora',
    guiasprocesadas = 'guiasprocesadas',
    userFac = 'userFac',
    pagoreco_validador = 'pagoreco_validador',
    PortafolioClient = 'PortafolioClient',
    clientSelected = 'clientSelected',
    setServiceRecolect = 'setServiceRecolect',
    updateVersion = 'updateVersion',
    versionPortal = 'versionPortal',
    modalUrl = 'modalUrl',
    provinces = 'provincias',
    isreturn = 'devoluciones',
    couponData = 'cupon',
    setServiceGuideData = 'SetServiceRecollectData',
    listadoExpressCenter = 'listadoExpressCenter',
    //new contracts
    user = 'user',
    parcels = 'parcels',
    numberGuide = 'numberGuide',
    tutoriales = 'tutoriales',
    collectionAddress = 'collectionAddress',
    routeActive = 'routeActive',
    lastRouteActive = 'lastRouteActive',
    queryParamsRouteActive = 'queryParamsRouteActive',
    currentLocation = 'currentLocation',
    addressInSesion = 'addressInSesion',
    courierId = 'courierId',
    productToEdit = 'productToEdit',
    editClient = 'editClient',
    membership = 'membresiaSeleccionada',
    dataOurServices = 'dataOurServices',
    cookieOurServices = '_HWAOurService',
    dataFaqTutorials = 'dataFaqTutorials',
    cookieFaqTutorials = '_HWAFaqTutorials',
    idAddressEdit = 'idAddressEdit',
    cookiePackageInformationModal = 'packageInformationModal',
    userCredential = 'userCredential',
    passwordCredential = 'passwordCredential',
    countryCredential = 'countryCredential',
    paramsQualityControlData = 'paramsQualityControlData',
    multiCountryData = 'multiCountryData',
    phoneDestiny = 'phoneDestiny',
    userWOL = 'userWOL', // user without login
    sessionKiosko = 'sessionKiosko',
    countrySelected = 'countrySelected',
}
